<template>
  <div>
    <b-form-group>
      <label>ステーション選択</label>
      <b-form-select v-model="station" v-on:change="selectedStation">
        <option :value="null" disabled>-- ステーションを選択してください --</option>
        <option
          v-for="item in stations.edges"
          :key="item.node.id"
          :value="item.node"
        >{{ item.node.id }}</option>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import { STATION_QUERY } from "../../../components/station-query";

export default {
  data() {
    return {
      station: null,
      stations: []
    };
  },
  props: {
    propStation: {
      default: null
    }
  },
  watch: {
    propStation: function() {
      this.station = this.propStation;
      this.$emit("getStation", this.station);
    }
  },
  created() {
    if (this.propStation) {
      this.station = this.propStation;
      this.$emit("getStation", this.station);
    }
  },
  apollo: {
    stations: {
      query: STATION_QUERY,
      result({ data, loading }) {},
      error(error) {
        console.error("station", error);
      }
    }
  },
  methods: {
    selectedStation: function() {
      this.$emit("getStation", this.station);
    }
  }
};
</script>
