<template>
  <div>
    <b-modal ref="dialog" @ok="deleteRobot">削除してもよろしいですか</b-modal>
  </div>
</template>

<script>
import { DELETE_ROBOT } from "../../components/robot-mutation";

export default {
  props: ["robotId"],
  methods: {
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteRobot: function() {
      this.$apollo
        .mutate({
          mutation: DELETE_ROBOT,
          variables: {
            id: this.robotId
          }
        })
        .then(data => {
          this.$emit("refetch");
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    }
  }
};
</script>