import gql from "graphql-tag";

export const STATION_QUERY = gql`
  query{
    stations{
      edges{
        node{
          id
        }
      }
    }
  }
`;
