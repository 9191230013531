import gql from "graphql-tag";

//作成
export const CREATE_ROBOT = gql`
  mutation createRobot($input:RobotInput!){
    createRobot(input:$input){
      robot{
        id
        uuid
        station{
          id
        }
      }
    }
  }`;

export const UPDATE_ROBOT = gql`
  mutation updateRobot($id:ID!,$input:RobotInput!){
    updateRobot(id:$id,input:$input){
      robot{
        id
        ip
        uuid
        station{
          id
        }
      }
    }
  }`;

export const DELETE_ROBOT = gql`
  mutation deleteRobot($id:ID!){
    deleteRobot(id:$id){
      robot{
        id
        ip
        uuid
        station{
          id
        }
      }
    }
  }`;
