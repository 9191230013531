<template>
  <b-card>
    <div v-if="$apollo.loading">Loading...</div>
    <div v-else>
      <b-row>
        <b-col>
          <station-form @getStation="setStationId" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="IPアドレス" label-for="ip">
            <b-form-input id="ip" type="text" v-model="robot.ip"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="UUID" label-for="uuid">
            <b-form-input id="uuid" type="text" v-model="robot.uuid"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button type="submit" size="sm" variant="primary" v-on:click="createRobot()">
        <i class="fa fa-dot-circle-o"></i> 作成
      </b-button>
    </div>
  </b-card>
</template>

<script>
import StationForm from "./form/StationForm";
import { CREATE_ROBOT } from "../../components/robot-mutation";

export default {
  components: {
    StationForm
  },
  data() {
    return {
      robot: {
        ip: null,
        uuid: null,
        stationId: null
      },
      createCheck: 0
    };
  },
  methods: {
    setStationId(station) {
      this.robot.stationId = station.id;
    },
    createRobot() {
      this.$apollo
        .mutate({
          mutation: CREATE_ROBOT,
          variables: {
            input: this.robot
          }
        })
        .then(data => {
          this.$emit("refetch");
        })
        .catch(error => {
          console.error(error);
          alert("登録エラー");
        });
    }
  }
};
</script>
