<template>
  <div>
    <!-- ダイアログ -->
    <b-modal ref="dialog" @hidden="close" hide-footer title="変更">
      <b-row>
        <b-col>
          <station-form @getStation="setStationId" :propStation="this.station" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="IPアドレス" label-for="ip">
            <b-form-input id="ip" type="text" v-model="robot.ip"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="UUID" label-for="uuid">
            <b-form-input id="uuid" type="text" v-model="robot.uuid"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button type="submit" size="sm" variant="primary" v-on:click="updateRobot">
        <i class="fa fa-dot-circle-o"></i> 更新
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import StationForm from "./form/StationForm";
import { UPDATE_ROBOT } from "../../components/robot-mutation";

export default {
  components: {
    StationForm
  },
  data() {
    return {
      robotId: null,
      station: null,
      robot: { ip: null, uuid: null, stationId: null }
    };
  },
  props: {
    propRobot: {
      type: Object,
      require: false,
      default: () => ({})
    }
  },
  watch: {
    propRobot: function() {
      this.init();
    }
  },
  methods: {
    init: function() {
      this.robotId = this.propRobot.id;
      this.robot.ip = this.propRobot.ip;
      this.robot.uuid = this.propRobot.uuid;
      this.robot.stationId = this.propRobot.station.id;
      this.station = this.propRobot.station;
    },
    close: function() {
      this.init();
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    setStationId(station) {
      this.robot.stationId = station.id;
    },
    // --------------------------------
    // 更新
    // --------------------------------
    updateRobot: function() {
      this.$apollo
        .mutate({
          mutation: UPDATE_ROBOT,
          variables: {
            id: this.robotId,
            input: this.robot
          }
        })
        .then(data => {
          this.$refs["dialog"].hide();
        })
        .catch(error => {
          alert("更新エラー");
        });
    }
  }
};
</script>
