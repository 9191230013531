<template>
  <b-row>
    <b-col cols="12" lg="4">
      <robot-create-form @refetch="robotRefetch" />
    </b-col>
    <b-col cols="12" lg="8">
      <robot-list ref="robotList" />
    </b-col>
  </b-row>
</template>
<script>
import RobotCreateForm from "../components/RobotCreateForm";
import RobotList from "../components/RobotList";

export default {
  components: {
    RobotCreateForm,
    RobotList
  },
  data() {
    return {};
  },
  methods: {
    robotRefetch: function() {
      this.$refs.robotList.robotDataRefetch();
    }
  }
};
</script>
