<template>
  <b-card>
    <robot-update-modal-form :propRobot="robot" ref="robotUpdateModalForm" />
    <robot-delete-modal-form
      ref="robotDeleteModalForm"
      :robotId="robotId"
      @refetch="robotDataRefetch"
    />
    <div v-if="$apollo.loading">Loading...</div>
    <div v-else>
      <b-table id="robot" responsive="sm" :fields="fields" :items="this.robots.edges">
        <template v-slot:cell(uuid)="data">
          <td>{{data.item.node.uuid}}</td>
        </template>
        <template v-slot:cell(ip)="data">
          <td>{{data.item.node.ip}}</td>
        </template>
        <template v-slot:cell(station)="data">
          <td>{{data.item.node.station.id}}</td>
        </template>
        <template v-slot:cell(update)="data">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="icon-pencil"
            v-on:click="showUpdateDialog(data.item.node)"
          ></b-button>
        </template>
        <template v-slot:cell(delete)="data">
          <b-button
            type="submit"
            size="sm"
            variant="danger"
            class="icon-trash"
            v-on:click="showDeleteDialog(data.item.node.id)"
          ></b-button>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import { ROBOT_QUERY } from "../../components/job-query";
import RobotUpdateModalForm from "./RobotUpdateModalForm";
import RobotDeleteModalForm from "./RobotDeleteModalForm";
import { nullCheckConvert } from "../../components/null-check";
export default {
  components: {
    RobotUpdateModalForm,
    RobotDeleteModalForm
  },
  data() {
    return {
      fields: [
        { key: "uuid", label: "UUID" },
        { key: "ip", label: "IPアドレス" },
        { key: "station", label: "ステーション" },
        { key: "update", label: "編集" },
        { key: "delete", label: "削除" }
      ],
      robot: null,
      robotId: null
    };
  },
  apollo: {
    robots: {
      query: ROBOT_QUERY,
      notifyOnNetworkStatusChange: true,
      result({ data, loading, networkStatus }) {},
      error(error) {
        console.error("Robot", error);
      }
    }
  },
  methods: {
    // --------------------------------
    // modalを表示
    // --------------------------------
    showUpdateDialog: function(robot) {
      this.robot = robot;
      this.$refs.robotUpdateModalForm.showDialog();
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showDeleteDialog: function(id) {
      this.robotId = id;
      this.$refs.robotDeleteModalForm.showDialog();
    },
    // --------------------------------
    // ロボット情報の再取得
    // --------------------------------
    robotDataRefetch: function() {
      this.$apollo.queries.robots.refetch();
    }
  }
};
</script>
